// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-components-home-home-js": () => import("./../../../src/components/Home/Home.js" /* webpackChunkName: "component---src-components-home-home-js" */),
  "component---src-components-search-search-js": () => import("./../../../src/components/Search/Search.js" /* webpackChunkName: "component---src-components-search-search-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-about-page-js": () => import("./../../../src/templates/aboutPage.js" /* webpackChunkName: "component---src-templates-about-page-js" */),
  "component---src-templates-content-page-js": () => import("./../../../src/templates/contentPage.js" /* webpackChunkName: "component---src-templates-content-page-js" */),
  "component---src-templates-navigation-page-js": () => import("./../../../src/templates/navigationPage.js" /* webpackChunkName: "component---src-templates-navigation-page-js" */),
  "component---src-templates-search-results-js": () => import("./../../../src/templates/searchResults.js" /* webpackChunkName: "component---src-templates-search-results-js" */),
  "component---src-templates-text-page-js": () => import("./../../../src/templates/textPage.js" /* webpackChunkName: "component---src-templates-text-page-js" */)
}

